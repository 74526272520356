
section.trusted {
	opacity: .5;
	margin-top: 2rem;
	& > h1 {
		text-align: center;
		margin-bottom: 1rem;
	}
	& > ul {
		display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, auto));
        grid-gap: 1rem;
        justify-content: center;
		li {
			max-height: 31px;
			object-fit: cover;
		}
	}
}

section.features {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    margin-bottom: 0 !important;
    &::after {
        background-color: rgba(226, 232, 240, .5);
    }
    & > h1 {
        text-align: center;
        color: #A855F7;
        font-size: 14px;
        font-weight: 300;
    }
    & > article {
        margin-top: .5rem !important;
        & > div {
            h2 {
                font-size: 26px;
                text-align: center;
                font-weight: 500;
                padding: .5rem 0;
            }
            h3 {
                font-size: 14px;
                text-align: center;
                font-weight: normal;
                padding: .5rem 0;
                max-width: 700px;
                margin: 0 auto;
                color: #64748B;
                line-height: 1.5;
            }
            & > ul {
                display: grid;
                grid-gap: 1.5rem;
                grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                margin-top: 2rem;
                li {
                    padding: 1.5rem;
                    border-radius: 10px;
                    background-color: #fff;
                    border: 2px solid #e2e8f0;
                    strong {
                        display: block;
                        margin: 1rem 0;
                        font-size: 20px;
                        font-weight: 500;
                        &::after {
                            content: '';
                            display: block;
                            width: 32px;
                            height: 2px;
                            background-color: #c084fc;
                            margin: 1rem 0;
                        }
                    }
                    p {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.5;
                        letter-spacing: .5px;
                    }
                }
            }
        }
    }
}

section.plans {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    margin-bottom: 0 !important;
    & > h1 {
        text-align: center;
        color: #A855F7;
        font-size: 14px;
        font-weight: 300;
    }
    & > article {
        margin-top: .5rem !important;
        & > div {
            h2 {
                font-size: 26px;
                text-align: center;
                font-weight: 500;
                padding: .5rem 0;
            }
            h3 {
                font-size: 14px;
                text-align: center;
                font-weight: normal;
                padding: .5rem 0;
                max-width: 700px;
                margin: 0 auto;
                color: #64748B;
                line-height: 1.5;
            }
            & > ul {
                display: grid;
                grid-gap: 1.5rem;
                grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                margin-top: 2rem;
                & > li {
                    padding: 1.5rem;
                    border-radius: 10px;
                    background-color: #fff;
                    border: 2px solid #e2e8f0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    strong {
                        text-align: center;
                        font-size: 20px;
                        font-weight: 400;
                        margin: 1rem auto;
                    }
                    & > p {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 300;
                    }
                    & > a {
                        background: #000;
                        border-radius: 4px;
                        padding: .5rem .75rem;
                        display: flex;
                        justify-content: center;
                        color: #fff;
                        font-weight: 300;
                        font-size: 12px;
                        margin: 2rem 0;
                    }
                    & > ul {
                        & > li {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 1.5;
                            margin-bottom: .5rem;
                            svg {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.faq {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    margin-bottom: 0 !important;
    & > h1 {
        text-align: center;
        color: #A855F7;
        font-size: 14px;
        font-weight: 300;
    }
}